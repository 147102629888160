import type { GetPaymentServiceInfoResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { deleteCookie, setCookie } from '@patrianna/shared-utils/cookie'
import type { TypedThunk } from 'src/store/types'
import { actions } from './slice'
import { SC_ENABLED } from 'app/constants/cookies'
import { setIsPaymentProvidersUpdateNeeded } from '@patrianna/shared-store/payments/store/actions'

export const {
  togglePWAMessage,
  saveLocationData,
  savePasswordPolicy,
  setForcementMode,
  setGoldCurrency,
  setSweepstakeEnabled,
  initApp,
  savePaymentProviders,
  saveKYCRequiredField,
  setSoftKycAutoCompleteAddressEnabled,
} = actions

export const getAvailablePaymentProvidersRequest =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    gateway
      .emit<GetPaymentServiceInfoResponse>({
        type: 'payment.GetPaymentServiceInfoRequest',
      })
      .then((res) => {
        const { withdrawProviders, purchaseProviders, softKycRequiredEnabled, softKycAutoCompleteAddress } = res
        dispatch(savePaymentProviders({ paymentProviders: { withdrawProviders, purchaseProviders } }))

        if (softKycRequiredEnabled) {
          dispatch(saveKYCRequiredField({ softKycRequiredEnabled }))
        }

        if (softKycAutoCompleteAddress) {
          dispatch(setSoftKycAutoCompleteAddressEnabled({ softKycAutoCompleteAddress }))
        }
        dispatch(setIsPaymentProvidersUpdateNeeded({ isPaymentProvidersUpdateNeeded: false }))

        // dispatch(saveAppsConfigs(body.apps))
      })
      .catch((err) => {
        dispatch(
          errorHandler(err, {
            type: 'payment.GetPaymentServiceInfoRequest',
          })
        )
      })
  }

export const enabledSweepstake =
  (enabled: boolean): TypedThunk =>
  (dispatch) => {
    if (enabled) {
      setCookie(SC_ENABLED, '1', { path: '/', expires: new Date(Date.now() + 31536000000) })
    } else {
      deleteCookie(SC_ENABLED)
    }
    dispatch(setSweepstakeEnabled({ enabled }))
  }
