import type { GameProduct, SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { ILevel } from '@patrianna/shared-patrianna-types/store/UserModule'
import type { TypedThunk } from 'src/store/types'
import { getAllGames } from 'src/utils/loadGameInfoGetInitialProps'
import { openDialog } from '../dialog/actions'
import { gamesLoadedSelector, gamesLoadingSelector, getAllGamesProductsSelector } from './selectors'
import { actions } from './slice'

export const { saveGameProducts, setLoading, saveAllGames } = actions

export const saveGamesProductsFromCategories =
  (categories: SwimlaneCategory[]): TypedThunk =>
  (dispatch) => {
    const products = categories.map((category) => category.products)
    const concatedProducts = products.flat()

    const games = concatedProducts.reduce((acc, current) => {
      if (!acc.find((el) => el.code === current.code)) {
        acc.push(current)
      }

      return acc
    }, [])

    dispatch(saveGameProducts({ games }))
  }

export const getAllProducts =
  (): TypedThunk =>
  (dispatch, getState, { errorHandler }) => {
    const isLoading = gamesLoadingSelector(getState())
    const isLoaded = gamesLoadedSelector(getState())
    if (isLoading || isLoaded) {
      return
    }

    dispatch(setLoading(true))
    getAllGames()
      .then((products) => {
        dispatch(saveAllGames(products))
      })
      .catch((err) => {
        dispatch(
          errorHandler(err, {
            type: 'getAllProducts',
            url: `/v1/get-all-products?brandName=${process.env.BRAND_NAME}`,
            browser: typeof window === 'undefined' ? 'no' : 'yes',
          })
        )
      })
      .finally(() => dispatch(setLoading(false)))
  }

export const addUnlockedGames =
  (ulockedGames: Array<GameProduct>): TypedThunk =>
  (dispatch, getState) => {
    const allGames = getAllGamesProductsSelector(getState())
    const concatedGames = allGames.map((game) => {
      if (ulockedGames && ulockedGames.find((it) => it.code === game.code)) {
        return {
          ...game,
          unlocked: true,
        }
      }

      return {
        ...game,
      }
    })
    dispatch(saveGameProducts({ games: concatedGames }))
  }

export const levelUpHandler =
  (levelInfo: ILevel): TypedThunk =>
  (dispatch) => {
    dispatch(openDialog({ modalName: 'VIP_NOTIFICATION_DIALOG', dialogProps: { levelInfo } }))
  }
