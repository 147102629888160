import { getCookie } from '@patrianna/shared-utils/cookie'
import { USER_COUNTRY } from 'app/constants/cookies'
import getCountryConfig from './index'

/**
 * This is a function takes user country from cookies and return config with options specific to the country.
 * Can be used only on client.
 *
 * @return {Object} CountryConfig
 *
 * @example
 *
 * const { profilePageFeature } = getCountryConfigClient()
 *
 * {profilePageFeature.enabled && <Component>}
 *
 */
export default function getCountryConfigClient() {
  const country = getCookie(USER_COUNTRY, document.cookie)
  const countryInfo = getCountryConfig(country)

  return countryInfo
}
