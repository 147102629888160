import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { GamesState } from 'store/types/GameModule'
import { sortGamesByType } from './utils'

const initialState: GamesState = {
  games: null,
  loading: false,
  loaded: false,
  allGames: [],
  lotteryDraws: null,
  lottoRules: {
    WITH_1_ADDITIONAL_BALL: {
      maxNumbers: 5,
      maxBonus: 1,
      maxNumbersArrayLength: 70,
      maxBonusNumbersArrayLength: 10,
    },
    WITH_2_ADDITIONAL_BALLS: {
      maxNumbers: 5,
      maxBonus: 2,
      maxNumbersArrayLength: 70,
      maxBonusNumbersArrayLength: 20,
    },
  },
}

export const gamesSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    saveGameProducts: (state, action: PayloadAction<{ games: Array<GameProduct> }>) => {
      const sortedGames = sortGamesByType(action.payload.games)
      state.games = sortedGames
    },

    saveAllGames: (state, action: PayloadAction<GameProduct[]>) => {
      state.allGames = action.payload
      state.loaded = true
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof gamesSlice.actions>>
export const { actions } = gamesSlice
