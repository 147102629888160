import { createSelector } from '@reduxjs/toolkit'
import type { ReduxState } from 'src/store/types'

const getAllPromotions = (state: ReduxState) => state.promotions.allPromotions
const getVisitedPromotions = (state: ReduxState) => state.promotions.visited

export const areVisitedPromotionsLoaded = createSelector(
  getVisitedPromotions,
  (visitedPromotions) => visitedPromotions.loaded
)

export const getVisitedPromotionIds = createSelector(
  getVisitedPromotions,
  (visitedPromotions) => visitedPromotions.promotions
)

export const getUnreadPromotionsAmount = createSelector(
  getAllPromotions,
  areVisitedPromotionsLoaded,
  getVisitedPromotionIds,
  (allPromotions, visitedPromotionsLoaded, visitedPromotionIds) => {
    if (!visitedPromotionsLoaded) {
      return 0
    }

    return allPromotions.filter(({ promoUrl }) => !visitedPromotionIds.includes(promoUrl)).length
  }
)

export const isUnreadPromotionSelector = createSelector(
  areVisitedPromotionsLoaded,
  getVisitedPromotionIds,
  (state: ReduxState, promotionId: string) => promotionId,
  (visitedPromotionsLoaded, visitedPromotionIds, promotionId) => {
    if (visitedPromotionsLoaded) {
      return !visitedPromotionIds.includes(promotionId)
    }

    return false
  }
)

export const isUnvisitedPromotionsIconDismissed = createSelector(
  getVisitedPromotions,
  ({ unvisitedPromotionsIconDismissed }) => unvisitedPromotionsIconDismissed
)
