import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { PromotionsState } from 'store/types/PromotionsModule'
import { addVisitedPromotionToStore, fetchVisitedPromotions, fetchAllPromotions } from './actions'
import type { PromotionPreview } from '@patrianna/shared-patrianna-types/store/PromotionsModule'

const initialState: PromotionsState = {
  allPromotions: [],
  visited: {
    loaded: false,
    promotions: [],
    unvisitedPromotionsIconDismissed: false,
  },
}

export const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    clearVisitedPromotions: (state) => {
      state.visited = initialState.visited
    },

    setUnvisitedPromotionsIconDismissed: (state, action: PayloadAction<{ dismissed: boolean }>) => {
      state.visited.unvisitedPromotionsIconDismissed = action.payload.dismissed
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addVisitedPromotionToStore, (state, action: PayloadAction<string>) => {
      state.visited.promotions.push(action.payload)
    })

    builder.addCase(fetchVisitedPromotions.fulfilled, (state, action: PayloadAction<string[]>) => {
      state.visited.promotions = action.payload || []
      state.visited.loaded = true
    })

    builder.addCase(fetchAllPromotions.fulfilled, (state, action: PayloadAction<PromotionPreview[]>) => {
      state.allPromotions = action.payload
    })
  },
})

export const {
  actions: { clearVisitedPromotions, setUnvisitedPromotionsIconDismissed },
} = promotionsSlice
