export { clearVisitedPromotions, setUnvisitedPromotionsIconDismissed } from './slice'
export {
  fetchVisitedPromotions,
  setPromotionAsVisited,
  fetchAllPromotions,
  dismissUnreadPromotionsIcon,
} from './actions'
export {
  isUnreadPromotionSelector,
  isUnvisitedPromotionsIconDismissed,
  getUnreadPromotionsAmount,
  getVisitedPromotionIds,
  areVisitedPromotionsLoaded,
} from './selectors'
