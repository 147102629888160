import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { PrerequisitesType } from '@patrianna/shared-patrianna-types/store/PrerequisitesModule'
import type { PrerequisitesState } from 'store/types/PrerequisitesModule'

const initialState: PrerequisitesState = null

export const prerequisitesSlice = createSlice({
  name: 'prerequisites',
  initialState,
  reducers: {
    prerequisitesChange: (state, action: PayloadAction<{ prerequisites: PrerequisitesType | null }>) =>
      action.payload.prerequisites,

    setOpenPrerequisites: (state, action: PayloadAction<{ prerequisites: PrerequisitesType | null }>) =>
      action.payload.prerequisites,
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof prerequisitesSlice.actions>>
export const { actions } = prerequisitesSlice
