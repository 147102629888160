import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
  LocationData,
  PasswordPolicyType,
  PaymentProviders,
} from '@patrianna/shared-patrianna-types/store/AppConfigModule'
import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import type { AppConfigState } from 'store/types/AppConfigModule'
import { openZendeskChatByCommand } from '@patrianna-payments/zendesk'

export const openZendeskChatByCommandActionName = 'openZendeskChatByCommandAction'

export const initialState: AppConfigState = {
  init: false,
  sweepstakeEnabled: false,
  passwordPolicy: {
    min: 8,
    max: 20,
    lowerCase: 1,
    upperCase: 1,
    special: 0,
    digits: 1,
    shouldNotMatchEmail: true,
  },
  paymentProviders: null,
  goldCurrency: null,
  forcement: 'default',
  isPWAMessageOpen: false,
  location: null,
  softKycRequiredEnabled: null,
  softKycAutoCompleteAddress: false,
}

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    initApp: (state) => {
      state.init = true
    },

    savePasswordPolicy: (state, action: PayloadAction<{ passwordPolicy: PasswordPolicyType }>) => {
      state.passwordPolicy = action.payload.passwordPolicy
    },

    // @TODO will remove
    setGoldCurrency: (state, action: PayloadAction<{ goldCurrency: Currencies }>) => {
      state.goldCurrency = action.payload.goldCurrency
    },

    saveLocationData: (state, action: PayloadAction<{ location: LocationData }>) => {
      state.location = action.payload.location
    },

    setSweepstakeEnabled: (state, action: PayloadAction<{ enabled: boolean }>) => {
      state.sweepstakeEnabled = action.payload.enabled
    },

    setSoftKycAutoCompleteAddressEnabled: (state, action: PayloadAction<{ softKycAutoCompleteAddress: boolean }>) => {
      state.softKycAutoCompleteAddress = action.payload.softKycAutoCompleteAddress
    },

    togglePWAMessage: (state, action: PayloadAction<{ isPWAMessageOpen: boolean }>) => {
      state.isPWAMessageOpen = action.payload.isPWAMessageOpen
    },

    // @TODO will remove
    setForcementMode: (state, action: PayloadAction<{ forcement: string }>) => {
      state.forcement = action.payload.forcement
    },

    savePaymentProviders: (state, action: PayloadAction<{ paymentProviders: PaymentProviders }>) => {
      state.paymentProviders = action.payload.paymentProviders
    },

    saveKYCRequiredField: (state, action: PayloadAction<{ softKycRequiredEnabled: boolean }>) => {
      state.softKycRequiredEnabled = action.payload.softKycRequiredEnabled
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openZendeskChatByCommandActionName, openZendeskChatByCommand)
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof appConfigSlice.actions>>
export const { actions } = appConfigSlice
